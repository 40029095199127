<template>
  <div class="row d-flex flex-column m-0" v-if="getProducts.length">
    <div class="p-0">
      <div class="carousel-wapper d-flex justify-content-center w-100">
        <Carousel v-bind="config" :wrapAround="true" ref="carouselRef" class="w-100">
          <Slide v-for="(product, index) in getProducts" :key="index">
            <Product
              :key="index"
              :product="product"
              :add-product="addProduct"
              :is-selected="checkProductSelected(product)"
              :the-cart-is-not-empty="theCartIsNotEmpty"
            />
          </Slide>

          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>

    <div class="col col-12 col-md-6 mx-auto mt-5 d-flex justify-content-center">
      <Button tag="Ver Carrinho" type="button" class="view-cart-button text-white btn fw-semibold d-flex align-items-center justify-content-center" @click="openCart">
        <Icon name="cart" />
        <span class="text-white">Ver Carrinho</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { Carousel, Slide, Pagination,Navigation } from 'vue3-carousel'
  import { computed, defineProps, ref, onMounted, useTemplateRef } from 'vue'
  import Product from '@/components/Product/ClaroProduct'
  import Button from '@/components/Form/Button/Button.vue'
  import 'vue3-carousel/dist/carousel.css'

  // * Props
  const props = defineProps({
    products: {
      type: Array,
      default: () => []
    },
    addProduct: {
      type: Function,
      default: () => {}
    },
    openCart: {
      type: Function,
      default: () => {}
    }
  })

  // * Setup
  const store = useStore()

  const carouselRef = useTemplateRef('carouselRef')

  const config = {
    snapAlign: 'center',

    // Breakpoints are mobile-first
    // Any settings not specified will fall back to the carousel's default settings
    breakpoints: {
      // 300px and up
      300: {
        itemsToShow: 1,
        snapAlign: 'center',
      },

      // 570 and up
      570: {
        itemsToShow: 2,
        snapAlign: 'start',
      },

      // 900 and up
      900: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
    },
  }

  // * Computed
  const hasProductInCartComputed = computed(() => store.getters['cart/hasProductInCart'])
  const getProducts = computed(() => props.products.length ? props.products : [])
  const theCartIsNotEmpty = computed(() => store.getters['cart/isCartEmpty'])

  // * Methods
  function checkProductSelected (product) {
    return hasProductInCartComputed.value(product?.product)
  }
</script>

<style lang="scss">
  .view-cart-button {
    padding: 9px 24px;
    gap: 10px;
    height: 3rem;
    width: 16.125rem;
    background: #1DA15A;
    border-radius: 8px;

    &:hover {
      background: #1b8a4f;
    }

    &:active {
      background: #1DA15A;
      border-color: #198049;
    }
  }

  .carousel__pagination {
    padding: 0;
    margin: 2.75rem 0 0 0;

    .carousel__pagination-button {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background-color: #FDEAE7;
    }

    .carousel__pagination-button--active {
      background-color: #D52B1E !important;
    }
  }

  .carousel__icon {
    color: black;
    font-size: 1.6rem;
  }

  .carousel__prev, .carousel__next {
    background: none;
  }

  .carousel__prev {
    left: -150px !important;
  }

  .carousel__next {
    right: -150px !important;
  }

  .carousel {
    max-width: 820px !important;
  }

  @media (max-width: 900px) {
    .carousel {
      max-width: calc(258px * 2 + 20px) !important;
    }
  }

  @media (max-width: 1050px) {
    .carousel__prev {
      display: none !important;
    }

    .carousel__next {
      display: none !important;
    }
  }

  @media (max-width: 1330px) {
    .carousel__prev {
      left: -50px !important;
    }

    .carousel__next {
      right: -50px !important;
    }
  }
</style>
