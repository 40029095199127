<template>
    <div class="card rounded-3 position-relative h-100" :class="{'card-selected shadow-card': isSelected, 'card-disabled': isNotTheSelectedProduct}">
        <div class="rounded-3 card-body h-100 d-flex flex-column justify-content-end">
            <h5 class="card-title product-title m-0 w-100 text-center h-100 d-flex align-items-center justify-content-center">{{ getProduct.name }}</h5>
            <img :src="product.product.link" class="d-inline-block w-100 product-flyer" :alt="product.product.name" />

            <div class="">
                <p v-if="hasAvailableProductTypeMcafeeComputed" class="card-text">{{ getProduct.description }}</p>
                <div class="dropdown rounded dropdown-menu-select-promotion">
                    <div v-if="getShowClaroFieldsComputed" class="show w-100 p-0 rounded-3">
                        <button :disabled="isNotTheSelectedProduct" @click="toggleDropdown" class="dropdown-item p-2 d-flex justify-content-between align-items-center rounded-3 w-100" type="button">
                            <p class="m-0" :class="{'opacity-50': isNotTheSelectedProduct}">{{ currencyBRFormat(promotion.price.price) }}</p>
                            <p class="m-0 fw-normal" :class="{'opacity-50': isNotTheSelectedProduct}">{{ promotion.description }}</p>

                            <Icon name="arrow-down-gray" />
                        </button>

                        <div v-show="dropdownOpen" class="dropdown-menu drop-menu-clarotv position-absolute show w-100" :class="{'top-negative': getPromotions.length >= 4}">
                            <a v-for="(promotion, loopIndex) in getPromotions" :key="loopIndex" class="dropdown-item p-2" @click="selectPromotion(loopIndex)" :class="{'selected-promotion': loopIndex === selectedPromotionIndex}">
                                <p class="m-0 lh-1 fw-normal text-muted">{{ promotion.description }}</p>
                                <b class="p-0 lh-1 d-block">{{ currencyBRFormat(promotion.price.price) }}</b>
                            </a>
                        </div>
                    </div>

                    <div v-else class="bg-white w-100 p-2 rounded">
                        <p class="m-0 text-wrap promotion-description text-dark fw-bold">{{ promotion.description }}</p>

                        <div class="text-danger fw-normal d-block mt-2">
                            <p class="m-0 text-decoration-line-through lh-1">De {{ currencyBRFormat(getProductPrice) }}
                            </p>
                            <p class="m-0 fw-normal">Por <b>{{ currencyBRFormat(promotion.price.price) }}/ano</b></p>
                        </div>
                    </div>
                </div>

                <div v-if="getShowClaroFieldsComputed && getAvailableProductTypeDecoded?.[0]?.id == 7" class="mt-2 p-0">
                    <div class="d-flex mt-1 justify-content-between">
                        <div class="btn-group btn-group-select-additional-points">
                            <button :disabled="isNotTheSelectedProduct" type="button" class="btn w-100 d-flex align-items-center justify-content-between btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedPointAdditional?.description ?? "Ponto adicional" }}</span>
                                <Icon name="arrow-down-gray" class="arrow-dropdown" />
                            </button>

                            <ul class="dropdown-menu p-0 rounded-0">
                                <li>
                                    <a @click="() => {selectedPointAdditional = null}" class="dropdown-item">Ponto adicional</a>
                                </li>
                                <li v-for="(description, key) in getProductResidentialPointAdditionalTypesComputed" :key="key">
                                    <a class="dropdown-item" @click="() => {selectedPointAdditional = description}">
                                        {{ description.description }} - {{ currencyBRFormat(description.price) }}
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="btn-group additional-points-select-amount">
                            <button :disabled="isNotTheSelectedProduct || !selectedPointAdditional" type="button" class="btn d-flex align-items-center justify-content-between btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{ selectedPointsIndex }}</span>
                                <Icon name="arrow-down-gray" class="arrow-dropdown" />
                            </button>

                            <ul class="dropdown-menu p-0 rounded-0">
                                <li>
                                    <a @click="({target}) => closeDropdownContext(target, () => selectedPointAdditional = null)" class="dropdown-item">0</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="({target}) => closeDropdownContext(target, () => selectedPointsIndex = 1)">1</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="({target}) => closeDropdownContext(target, () => selectedPointsIndex = 2)">2</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <p class="m-0 text-center text-danger mt-3 fw-semibold" :class="{'text-white': isSelected}">Valor total</p>

                    <p class="total-text fw-semibold m-0 text-center">{{ formatMoney(updateTotalPrice()) }} / mês</p>
                </div>

                <Button
                    :class="{'opacity-50': isNotTheSelectedProduct}"
                    class="btn-select-product mt-3 fw-semibold btn w-100 d-flex align-items-end justify-content-center lh-1 text-uppercase text-white"
                    type="button"
                    :tag="getProductName"
                    @click="addProductEvent()"
                    :disableTracking="isSelected"
                >
                    <Icon name="bag-promotion" class="icon-bag-promotion" /> {{ isSelected ? "Selecionado" : "Selecionar" }}
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import MoneyService from "@/services/numbers/money";
import { mapGetters } from "vuex";
import Button from "@/components/Form/Button/Button.vue";
import Icon from "@/components/Icon/Icon.vue";

export default {
    name: "Product",
    data() {
        return {
            count: 0,
            showModal: false,
            selectedMessage: 0,
            dropdownOpen: false,
            selectedPromotionIndex: -1,
            selectedPointsIndex: 0,
            isOpen: null,
            selectedPointAdditional: null,
            selectedPointAdditionalPrice: 0,
        };
    },
    components: {
        Button,
        Icon,
    },
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
        isSelected: {
            type: Boolean,
            default: () => false,
        },
        addProduct: {
            type: Function,
            default: () => { },
        },
        theCartIsNotEmpty: {
            type: Boolean,
            default: () => false
        }
    },
    watch: {
        selectedPointsIndex: "updateTotalPrice",
    },
    computed: {
        isNotTheSelectedProduct() {
            return this.theCartIsNotEmpty && !this.isSelected
        },
        ...mapGetters({
            hasAvailableProductTypeMcafeeComputed: "saleFlow/product/hasAvailableProductTypeMcafee",
            hasAvailableProductTypeClaroComputed: "saleFlow/product/hasAvailableProductTypeClaro",
            getAvailableProductTypeDecoded: "saleFlow/product/getAvailableProductTypeDecoded",
            getProductResidentialPointAdditionalTypesComputed: "saleFlow/product/getProductResidentialPointAdditionalTypes",
            getCartDetailsComputed: "cart/getCartDetails",
            getShowClaroFieldsComputed: "address/getShowClaroFields",
        }),
        getProduct() {
            return !this.product?.product ? {} : this.product?.product;
        },
        getPromotions() {
            return !this.getProduct?.promotions ? [] : this.getProduct?.promotions;
        },
        getProductPrice() {
            return this.getProduct?.price?.price || 0;
        },
        currencyBRFormat() {
            return (value) => MoneyService.currencyBRFormat(value || 0);
        },
        getCartTotalPrice() {
            return this.getCartDetailsComputed?.total || 0;
        },
        filteredMessages() {
            return this.messages.filter(
                (_, index) => index !== this.selectedMessage && this.dropdownOpen
            );
        },
        promotion() {
            return this.getPromotions[this.selectedMessage] || {};
        },
        getPricePoint() {
            const prices = this.getProductResidentialPointAdditionalTypesComputed
                .map((description) => description.price)
                .filter((price) => typeof price === "number");
            return prices;
        },
        getSelectedPromotionInfo() {
            if (this.selectedPromotion) {
                return {
                    id: this.selectedPromotion.id,
                    price: this.selectedPromotion.price,
                };
            } else {
                return {
                    id: null,
                    price: 0,
                };
            }
        },
        getProductName() {
            return this.getProduct?.name || "";
        }
    },
    methods: {
        closeDropdownContext(context, callback) {
            context.closest('.dropdown-menu').classList.remove('show')
            callback()
        },
        selectPromotion(index) {
            this.selectedMessage = index;
            this.dropdownOpen = false;
            this.selectedPromotionIndex = index;
            this.selectedPromotion = this.getPromotions[index];
            this.selectedPointsIndex = 0;
        },
        getPromotionById(promotionId) {
            return this.getProductResidentialPointAdditionalTypesComputed.find(
                (promotion) => promotion.id === promotionId
            );
        },
        changeCount(value) {
            console.log(this.selectedPointsIndex)
            const newPointsIndex = Math.max(0, Math.min(2, this.selectedPointsIndex + value));
            console.log(newPointsIndex)
            this.selectedPointsIndex = newPointsIndex;
            if (this.selectedPointsIndex < 1) {
                this.selectedPointAdditional = null;
            }
            this.updateTotalPrice();
        },
        updateTotalPrice()
        {
            if (isNaN(this.promotion.price.price)) {
                this.selectedPointsIndex = 0
                return 0
            }

            if (! this.selectedPointAdditional) {
                return this.promotion.price.price
            }

            const basePrice = parseFloat(this.promotion.price.price) || 0

            if (this.selectedPointsIndex < 1) {
                this.selectedPointsIndex = 1
            }

            const selectedPointPrice = parseFloat(this.selectedPointAdditional.price)

            const totalPrice = basePrice + selectedPointPrice * this.selectedPointsIndex

            this.selectedPointAdditionalPrice = selectedPointPrice

            return totalPrice
        },

        formatMoney(value) {
            return MoneyService.currencyBRFormat(value || 0);
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        selectMessage(index) {
            this.selectedMessage = index;
            this.dropdownOpen = false;
        },
        addProductEvent() {
            this.openViabilityModal();
            const promotion = this.promotion || this.getPromotions[0] || null;
            const productResidentialPointAdditionalAmount =
                this.selectedPointsIndex;
            const productResidentialPointAdditionalType =
                this.selectedPointAdditional || null;
            this.addProduct({
                product: this.getProduct,
                promotion: promotion,
                productResidentialPointAdditionalAmount:
                    productResidentialPointAdditionalAmount,
                productResidentialPointAdditionalType:
                    productResidentialPointAdditionalType,
            });
        },
        openViabilityModal() {
            this.showModal = true;
        },
        closeViabilityModal() {
            this.showModal = false;
        },
        changeAdditionalPoints(e) {
            if (e.target.value == 0) {
                this.selectedPointAdditional = null;
            }
        },
    },
    created() {
        this.selectedPromotionIndex = 0;
    },
};
</script>

<style lang="scss" scoped>
    .card-disabled {
        background-color: #f1f1f1 !important;
    }

    .product-title {
        font-size: 1.375rem;
    }

    .shadow-card {
        box-shadow: 0px 0rem 1rem 1px rgb(72 72 72 / 18%) !important;
    }

    .total-text {
        font-size: 1.3938rem;
    }

    .total-text, .product-title {
        color: #29333D;
    }

    .product-flyer {
        border-radius: 0.25rem;
        margin: 16px 0;
    }

    .top-negative {
        top: -130px;
    }

    .btn-select-product {
        background-color: #D52B1E;
        font-size: 1rem;
        letter-spacing: 0.0125em;
        height: 40px;
        padding: 8px 24px 8px 24px;
        gap: 8px;

        &:active {
            background-color: #a5261d;
            border-color: #a5261d;
            color: white;
        }
    }

    .btn-group-select-additional-points {
        width: 70%;
    }

    .dropdown-select-additional-points-container {
        border:1px solid red;
    }

    .dropdown-item {
        cursor: pointer;
    }

    .select-arrow {
        width: 0.8rem;
    }

    .icon-bag-promotion {
        width: 1.3rem;
    }

    .card {
        width: 258px !important;
        min-width: 258px !important;
        border-color: #94999E;
    }

    .promotion-description {
        font-size: 0.8rem;
    }

    .card-selected {
        border-color: #d52b1e;
    }

    .selected-promotion {
        background-color: lightgray;
        font-weight: bold;
    }

    .dropdown-menu-select-promotion, .btn.dropdown-toggle {
        color: #212529 !important;
        border-color: #94999E !important;
        border-width: 2px !important;
        border-style: solid;
    }

    .btn.dropdown-toggle {
        background-color: white;

        &::after {
            content: none;
            display: none;
        }
    }

    ul.dropdown-menu {
        border-color: #a9a9a9 !important;

        .dropdown-item {
            font-size: 0.9rem !important;
        }
    }

    button.dropdown-item {
        outline: none !important;
        border-radius: 0.2rem !important;
    }

    button.dropdown-item:disabled, button.dropdown-toggle:disabled {
        background-color: #e0e0e0 !important;
        border-color: #62686f !important;
    }
</style>
