<template>
  <div class="header">
    <header class="m-0 row">
      <div class="col-12 d-flex align-items-center">
        <div class="col-6 p-3">
          <router-link :to="{handleLogo}">
            <img :src="partnerImgSrc" alt="logo do parceiro de venda" class="img-auto header-partner-logo" />
          </router-link>
        </div>

        <div class="d-flex p-3 gap-2 col-6 align-items-center justify-content-end">
          <div v-if="getShowClaroFieldsComputed">
            <router-link @click="openRequests" to="">
              <img :src="partnerIconSrc" alt="icone de usuário" class="img-auto" />
            </router-link>
          </div>

          <div v-if="!hideIcon">
            <router-link to="" @click="openCart">
              <img :src="partnerIconBySrc" alt="icon" class="img-auto" />
            </router-link>

            <b-badge variant="danger" v-show="getCartLengthComputed">
              {{ getCartLengthComputed }} <span class="sr-only"></span>
            </b-badge>
          </div>
        </div>
      </div>
    </header>

    <Login v-if="showModal" @close="toggleModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { myOrders } from "@/modules/customer/services/orders.service.js";
import Login from "@/components/Login/Login.vue";
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
import Configuration from "@/services/configuration";

export default {
  name: "Header",
  props: {
    login: {
      type: Function,
      default: () => {},
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    logoRedirectUrl: {
      type: String,
    }
  },
  data() {
    return {
      partnerIconBySrc: null,
      partnerIconSrc: null,
      partnerImgSrc: null,
      currentPage: 1,
      showModal: false
    };
  },
  components: {
    Login
  },
  computed: {
    ...mapGetters({
      getPartnerConfigurationComputed: "getPartnerConfiguration",
      getCartLengthComputed: "cart/getCartLength",
      hasAvailableProductTypeClaroComputed: "saleFlow/product/hasAvailableProductTypeClaro",
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      getCustomerComputed: "customer/getCustomer",
      authenticateUserComputed: "auth/AuthorizationCustomer",
      getOrdersComputed: "saleFlow/getOrders",
      getSaleOfServiceComputed: "saleFlow/getSaleOfService",
      getCurrentAddressComputed: 'cart/getCurrentAddress'
    }),
    stringAddress() {
      return this.getCurrentAddressComputed?.streetName + ", " + this.getCurrentAddressComputed?.cityName + ", " + this.getCurrentAddressComputed?.stateName;
    }
  },
  watch: {
    getPartnerConfigurationComputed() {
      this.setPartnerConfigurationLogoSrc();
      this.setPartnerConfigurationIconSrc();
      this.setPartnerConfigurationIconBySrc();
    }
  },
  mounted() {
    this.setPartnerConfigurationLogoSrc();
    this.setPartnerConfigurationIconSrc();
    this.setPartnerConfigurationIconBySrc();
  },
  methods: {
    setPartnerConfigurationLogoSrc() {
      this.getPartnerConfigurationComputed
        ?.logo()
        .then((module) => (this.partnerImgSrc = module?.default));
    },
    setPartnerConfigurationIconSrc() {
      this.getPartnerConfigurationComputed
        ?.icon()
        .then((module) => (this.partnerIconSrc = module?.default));
    },
    setPartnerConfigurationIconBySrc() {
      this.getPartnerConfigurationComputed
        ?.iconBy()
        .then((module) => (this.partnerIconBySrc = module?.default));
    },
    ...mapActions({
      openCartAction: "cart/openCart",
      openRequestAction: "cart/openRequest",
      authenticateUserAction: "auth/authenticateUser",
      refreshOrdersAction: "saleFlow/refreshOrders",
      getAllProductsCatalogAction: 'saleFlow/getAllProductsCatalog',
      setAvailableProductTypeAction: 'saleFlow/product/setAvailableProductType',
      openRegionalizationModalAction: 'address/openRegionalizationModal',
      setRegionalizationModalCallbackAction: 'address/setRegionalizationModalCallback',
      setShowClaroFieldsAction: 'address/setShowClaroFields',
      clearCurrentAddressAction: 'address/clearCurrentAddress',
    }),
     handleLogo() {
      if (this.logoRedirectUrl) {
        return this.$router.push({ path: this.logoRedirectUrl })
      }
    },
    getProducts() {
      this.ClaroFieldsAction();
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[productTypesOptionsConstants.CLARO_RESIDENTIAL_TV],
      ])
      .then(() => this.getAllProductsCatalogAction())
    },
    startRegionalizationFlow () {
      if (this.getCurrentAddressComputed?.id === null || this.getCurrentAddressComputed?.id === undefined) {
        this.getProducts();
        return;
      }
      this.openRegionalizationModalAction();
      this.setRegionalizationModalCallbackAction(() => {
        this.getProducts();
      });
    },
    ClaroFieldsAction(){
      this.setShowClaroFieldsAction();
    },
    McafeeStaticAction() {
      this.setShowMcafeeStaticAction();
    },
    openCart() {
      this.openCartAction();
    },
    openRequests() {
      if (this.isAuthenticateUserComputed === false) {
        this.$swal(
          "Para acessar seus pedidos, é necessário estar logado.",
          " ",
          "warning"
        );
        this.showModal = true;
        return;
      }
      this.openRequestAction().then(() => {
        this.orders();
        if (
          this.getSaleOfServiceComputed?.id === null ||
          this.getSaleOfServiceComputed?.id === undefined
        ) {
          this.$swal("Você não possui pedidos ativos.", " ", "info");
        }
        this.refreshOrdersAction();
      });
    },
    async orders() {
      try {
        const currentPage = this.currentPage;
        const response = await myOrders(currentPage);
      } catch (error) {
        this.$swal(
          "Ocorreu um erro de processamento, por favor, tente novamente.",
          " ",
          error
        );
        this.$swal("Erro ao buscar pedidos:", error);
      }
    },
    changeAddress() {
      this.clearCurrentAddressAction(this.getCurrentAddressComputed);
      this.startRegionalizationFlow();
      return;
    }
  }
};
</script>

<style>
  .header-partner-logo {
    width: 10.5rem;
  }
</style>
