<template>
  <div class="address-bar p-2 d-flex align-items-center gap-3">
    <Icon name="pin" />
    <p class="m-0 text-white">{{ stringAddress }}</p>
    <a class="change-address text-white fw-semibold" @click="changeAddress()">Alterar CEP</a>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
import Configuration from "@/services/configuration";

export default {
  name: "AddressBar",
  data() {
    return {
      showModal: false,
      hideDivInHeader: false,
    };
  },
  computed: {
    ...mapGetters({
      getCurrentAddressComputed: "address/getCurrentAddress",
      productsComputed: "saleFlow/product/getProducts"
    }),
    stringAddress() {
      const streetName = this.getCurrentAddressComputed?.streetName || "";
      const cityName = this.getCurrentAddressComputed?.cityName || "";
      const stateName = this.getCurrentAddressComputed?.stateName || "";

      return (
        streetName +
        (streetName && cityName ? ", " : "") +
        cityName +
        (cityName && stateName ? ", " : "") +
        stateName
      );
    }
  },
  methods: {
    ...mapActions({
      getAllProductsCatalogAction: "saleFlow/getAllProductsCatalog",
      setAvailableProductTypeAction: "saleFlow/product/setAvailableProductType",
      openRegionalizationModalAction: "address/openRegionalizationModal",
      setRegionalizationModalCallbackAction:"address/setRegionalizationModalCallback",
      setShowClaroFieldsAction: "address/setShowClaroFields",
      clearCurrentAddressAction: "address/clearCurrentAddress"
    }),
    getProducts() {
      this.ClaroFieldsAction();
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[
          productTypesOptionsConstants.CLARO_RESIDENTIAL_TV
        ],
      ]).then(() => this.getAllProductsCatalogAction());
    },
    startRegionalizationFlow() {
      if (
        this.getCurrentAddressComputed?.id === null ||
        this.getCurrentAddressComputed?.id === undefined
      ) {
        this.getProducts();
        return;
      }
      this.openRegionalizationModalAction();
      this.setRegionalizationModalCallbackAction(() => {
        this.getProducts();
      });
    },
    ClaroFieldsAction() {
      this.setShowClaroFieldsAction();
    },
    changeAddress() {
      this.clearCurrentAddressAction(this.getCurrentAddressComputed);
      this.startRegionalizationFlow();
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
  .address-bar {
    background-color: #e3262e;

    a {
      cursor: pointer;
    }
  }
</style>
