<template>
  <div class="banner-clarotv-container">
    <Banner />
  </div>

  <div class="bg-light py-4">
    <div class="container px-md-0">
      <ClaroTvAccordionHeader />
    </div>

    <p class="m-0 text-center mt-5 produtos-disponiveis-titulo">Produtos disponíveis</p>

    <!-- <div class="mt-1 border border-danger border-4"> -->
      <ClaroProductsCatalog :products="productsComputed" :add-product="addProduct" :open-cart="openCart" />
    <!-- </div> -->
  </div>

  <Faq />

  <ViabilityModal
    v-if="showViabilityModal"
    :product="productSelectedToAddCart"
    :close-modal="closeViabilityCallback"
    :set-product-viability="setProductViabilityCallback"
    :close="close"
  />
</template>

<script>
import ClaroProductsCatalog from "@/components/Sale/ClaroProductsCatalog"
import Configuration from "@/services/configuration"
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants"
import ViabilityModal from "@/components/Sale/Viability/ViabilityModal.vue"
import { mapActions, mapGetters } from "vuex"
import Banner from "./components/Banner"
import ClaroTvAccordionHeader from "./components/AccordionHeader"
import Faq from "./components/Faq"

export default {
  name: "Homepage",
  data() {
    return {
      showViabilityModal: false,
      productSelectedToAddCart: {
        product: {},
        promotion: {},
        productResidentialPointAdditionalAmount: 0,
        productResidentialPointAdditionalType: null
      },
      productSelectedHasViability: false,
    }
  },
  components: {
    ClaroTvAccordionHeader,
    Faq,
    Banner,

    ClaroProductsCatalog,
    ViabilityModal,
  },
  computed: {
    ...mapGetters({
      productsComputed: 'saleFlow/product/getProducts',
      getCurrentAddressComputed: 'address/getCurrentAddress',
      authenticatedCustomerComputed: 'auth/AuthorizationCustomer',
      getCookiesPolicyAccepted: 'getCookiesPolicyAccepted'
    }),
  },
  watch: {
    getCookiesPolicyAccepted() {
      if (this.getCookiesPolicyAccepted === true) {
        this.startRegionalizationFlow();
      }
    }
  },
  props: {
    title: String
  },
  methods: {
    ...mapActions({
      getAllProductsCatalogAction: 'saleFlow/getAllProductsCatalog',
      setAvailableProductTypeAction: 'saleFlow/product/setAvailableProductType',
      addProductCartAction: 'cart/addProductCart',
      openCartAction: 'cart/openCart',
      openRegionalizationModalAction: 'address/openRegionalizationModal',
      setRegionalizationModalCallbackAction: 'address/setRegionalizationModalCallback',
      setShowClaroFieldsAction: 'address/setShowClaroFields'
    }),
    getProducts() {
      this.ClaroFieldsAction()
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[productTypesOptionsConstants.CLARO_RESIDENTIAL_TV],
      ]).then(() => this.getAllProductsCatalogAction())
    },
    addProduct({ product, promotion, productResidentialPointAdditionalAmount, productResidentialPointAdditionalType }) {
      this.productSelectedToAddCart = {
        product,
        promotion,
        productResidentialPointAdditionalAmount,
        productResidentialPointAdditionalType
      };

      if (this.productSelectedHasViability === true) {
        this.showViabilityModal = false
        this.closeViabilityCallback()
      }
      else {
        this.showViabilityModal = true
      }
    },
    closeViabilityCallback() {
      if (this.productSelectedHasViability) {
        this.addProductCartAction({
          product: this.productSelectedToAddCart?.product,
          promotion: this.productSelectedToAddCart?.promotion,
          productResidentialPointAdditionalAmount: this.productSelectedToAddCart?.productResidentialPointAdditionalAmount,
          productResidentialPointAdditionalType: this.productSelectedToAddCart?.productResidentialPointAdditionalType
        });
      }
    },
    setProductViabilityCallback({ validated }) {
      this.productSelectedHasViability = validated;
    },
    openCart() {
      this.openCartAction()
    },
    ClaroFieldsAction(){
      this.setShowClaroFieldsAction()
    },
    openViability() {
      this.showViabilityModal = true
    },
    close(){
      this.showViabilityModal = false
    },
    startRegionalizationFlow () {
      if (this.getCurrentAddressComputed?.id) {
        this.getProducts()
        return
      }
      this.openRegionalizationModalAction()
      this.setRegionalizationModalCallbackAction(() => {
        this.getProducts()
      })
    },
  }
}
</script>

<style>
  .produtos-disponiveis-titulo {
    font-size: 2rem;
    line-height: 2.3438rem;
    text-decoration-skip-ink: none;
    font-weight: 700;
    color: #29333D;
    margin-bottom: 2rem;
  }
</style>
