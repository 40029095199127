<template>
    <div class="claro-tv-faq">
        <div class="text-center my-5">
            <p class="m-0 fs-1">Perguntas Frequentes</p>
            <p class="m-0 fs-5">Essas respostas podem ajudar</p>
        </div>

        <div class="container mb-5">
            <AccordionContainer :items="faqItems" :isSingleOpen="true" />
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'

    import AccordionContainer  from '@/components/Accordion/Container'

    const faqItems = ref([
        {
          title: "Quais são os canais disponíveis na Claro TV?",
          content: "Cada plano Claro TV possui uma grade de programação diferente. Em todas elas você poderá assistir a canais como Disney Channel, Warner, Discovery, ESPN, SportTV e muito mais. Acesse a grade online e consulte toda a programação disponível nos planos: Conheça a grade de canais do plano Fácil HD; Conheça a grade de canais do plano Mix HD; Conheça a grade de canais do plano Top 4K;"
        },
        {
          title: "O que é NOW?",
          content: "O NOW é a plataforma de streaming da Claro exclusiva para clientes. Ela está disponível sem custo adicional em diversos planos da operadora e oferece conteúdos de filmes, séries, programas infantis, notícias ao vivo e muito mais."
        },
        {
          title: "O que são os recursos da Claro TV??",
          content: " São algumas funcionalidades inclusas na TV ou no controle remoto. Elas incluem o replay TV, para voltar e não perder nenhum detalhe do que estiver assistindo, gravador de programação e recurso PIP (Picture in Picture), para assistir até 2 conteúdos ao mesmo tempo na sua tela."
        },
        {
          title: "Qual é o telefone da Claro TV?",
          content: " O telefone da Claro TV para assinar um plano é o 0800 338 2121. Quem já é cliente pode ligar grátis no canal de atendimento 106 21."
        },
        {
          title: "O plano Fácil HD não existe mais?",
          content: "O pacote Fácil HD ainda está sendo disponibilizado pela Claro. É possível contratar das seguintes formas: • De forma individual, por R$ 79,99/mês; • No combo com internet banda larga ou móvel, por R$ 79,99/mês; • Com canais extras, podendo ser HBO ou Telecine, por R$ 89,99/mês, ou Premiere, por R$ 109,99/mês."
        },
        {
          title: "A Claro tv+ (NOW) não está funcionando, o que preciso fazer?",
          content: "A Claro tv+ funciona simultaneamente usando os sinais da rede de TV e Banda Larga, se um dos dois serviços estiver com problemas, o serviço não funcionará corretamente. Nos casos de mensagens como 'procurando rede', 'rede não encontrada' ou 'atualizando catálogo', é preciso checar se existe algum problema técnico na região ou se é necessária uma visita técnica."
        },
        {
          title: "Como alterar a senha de compra Claro tv+ (NOW)?",
          content: "A senha inicial de aluguel de conteúdo da Claro tv+ é 0000. Por segurança, sugerimos que esta senha seja alterada/personalizada. A senha de compra é a mesma senha de Bloqueio de canais (Controle de Pais).Para alterá-la, acesse 'Configurações da conta' > 'Alterar senha' no menu do clarotvmais.com.br ou no app Claro tv+."
        }
    ])
</script>
