<template>
    <div class="clarotv-accordion-header shadow-lg mx-auto rounded-3" @click="isOpen = !isOpen">
        <div class="d-flex align-items-center justify-content-between p-3">
            <h1 class="fw-semibold fs-4 text-white m-0">ClaroTv +</h1>

            <div class="arrow-control fs-5">
                <img
                    src="@/assets/icons/arrow-down-white.svg"
                    class="arrow-content"
                    :class="{'arrow-top': isOpen}"
                    alt="icone de seta para baixo quando o conteúdo está fechado, ou para cima quando está aberto"
                />
            </div>
        </div>

        <div v-if="isOpen" class="bg-white p-3 content">
            <p class="m-0 text-dark">A líder em TV e conteúdo, inovando de novo! Com + de 100 canais ao vivo, 50 mil conteúdos On Demand, milhares de filmes e séries liberados no Claro vídeo e muito mais. A Claro pensou em tudo e está transformando o melhor em excepcional. Agora você tem a liberdade de escolher como e onde quer assistir seus conteúdos favoritos da TV ao vivo e On Demand.</p>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'

    const isOpen = ref(false)
</script>

<style lang="scss" scoped>
    .clarotv-accordion-header {
        background: #D52B1E;
        width: 69rem;
        cursor: pointer;
    }

    @media screen and (max-width: 1200px) {
        .clarotv-accordion-header {
            width: 100%;
        }
    }

    .arrow-control {
        fill: red;
    }

    .content {
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
    }

    .arrow-top {
        transform: rotate(180deg);
    }

    .arrow-content {
        transition: all .2s;
    }
</style>
